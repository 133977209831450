import React, { useState, useEffect, useContext, useCallback } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import i18n from "../../imports/i18n";
import { ethers, utils } from "ethers";
import Lottie from "react-lottie-player";

//Components
import {
  CustomModal,
  Icon,
  Icons,
  SplashScreenStepper,
  StyledButton,
  CarSimpleCard,
} from "../../components";
import { Loading } from "../../imports/animations";

//Imports
import { sdk, sleep } from "../../imports/utils";
import {
  queryCreateNFT,
  queryCarsData,
  setCarProperty,
  // waitIndidTask,
} from "./queries";
import {
  colors,
  MINT_STEPS,
  TOAST_CONFIG,
  getCarObject,
} from "../../imports/constants";
import { AuthContext } from "../../redux-observables/firebase/context";
import { toast } from "react-toastify";

const UNIT = {
  brand: "",
  model: "",
  modelDescription: "",
  mileage: "km",
  series: "",
  version: "",
  vin: "",
};

export default function CreateNFT() {
  const { saveAction, user, getRegion, signOut } = useContext(AuthContext);
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [step, setStep] = useState(0);
  const [carsData, setCarsData] = useState([]);
  const [currentCar, setCurrentCar] = useState();
  const [carPropertyModalOpen, setCarPropertyModalOpen] = useState(false);
  const [isOwnedByMe, setIsOwnedByMe] = useState(false);
  const [isOwnedByOthers, setIsOwnedByOthers] = useState(false);
  const [isNftMinted, setIsNftMinted] = useState(false);
  const [disableMainButton, setDisableMainButton] = useState(false);
  const [isRequestButtonDisabled, setRequestButtonDisabled] = useState(false);
  const [nft, setNft] = useState();

  useEffect(() => {
    // console.log("fetchCars in createNFT");
    fetchCars();
  }, []);

  // this function is triggered when we have to mint or update (or transfer)
  const createNFT = useCallback(async () => {
    setStep(1);

    try {
      // console.log("queryCreateNFT Start");
      // console.log(currentCar, user.sub || user.uid);
      setTimeout(() => setStep(2), 7000);
      setTimeout(() => setStep(3), 12000);

      const { status } = await queryCreateNFT(currentCar, user.sub || user.uid);

      // console.log("queryCreateNFT status", status);

      //  setStep(2);

      // setTimeout(() => setStep(5), 6000);

      async function recursiveTask() {
        await sleep(2000);
        //const status = await waitIndidTask(taskId);

        setNft({ status });

        if (status === "SUCCESS") {
          saveAction(null);
          setLoader(false);
          setStep(4);
          toast.success(i18n.t("messages.notarization_success"), TOAST_CONFIG);

          setTimeout(() => {
            setStep(5);
            // navigazione con ritardo sulla home per aspettare che i dati si aggiornino anche sul DB
            // così l'utente vede l'NFT aggiornato anche nella home
            setTimeout(() => {
              setDisableMainButton(false);
              navigate("/");
            }, 2500);
          }, 1000);
        } else if (
          status === "REVERTED" ||
          status === "UNHANDLED" ||
          status === "FAILED" ||
          status === "TIMEOUT" ||
          status === "ERROR" ||
          status === "CANCELLED"
        ) {
          setError(true);
          setLoader(false);
          setDisableMainButton(false);
          setStep(5);
        } else {
          recursiveTask();
        }
      }

      recursiveTask();
    } catch (error) {
      setStep(0);
      setLoader(false);
      setDisableMainButton(false);
      toast.error(i18n.t("messages.something_went_wrong"));
    }
  }, [currentCar, user]);

  /**
   * Fetch cars query
   */
  const { isFetching: fetchingCars, refetch: fetchCars } = useQuery(
    "fetchCars",
    async () => {
      if (!fetchingCars) {
        const cars = await queryCarsData(user.uid || user.sub);

        if (cars === "logout") {
          signOut();
        } else if (cars.length) {
          let formattedCars = [];

          for (let i = 0; i < cars.length; i++) {
            let {
              abi,
              certificate: address,
              modelName,
            } = getCarObject(cars[i].model);

            if (!abi || !address) {
              ({
                abi,
                certificate: address,
                modelName,
              } = getCarObject(cars[i].modelDescription.split(" ")[0]));
            }

            if (abi && address) {
              const carContract = sdk.getContract(address, abi);

              const tokenId = parseInt(
                (
                  await carContract.getCarTokenId(
                    utils.keccak256(Buffer.from(cars[i].vin)),
                  )
                ).toString(),
              );

              formattedCars.push({
                ...cars[i],
                tokenId,
                mileage: cars[i].odometer,
                model: modelName,
              });
            }
          }

          if (!formattedCars.length) {
            // console.log("formattedCars.length redirect to home");
            saveAction(null);
            navigate("/");
          } else if (formattedCars.length === 1) {
            // console.log("formattedCars.length === 1 : ", formattedCars);
            setCurrentCar(formattedCars[0]);
          } else {
            // console.log("formattedCars : ", formattedCars);
            setCarsData(formattedCars);
          }
        } else {
          // console.log("navigate to home if no cars");
          saveAction(null);
          navigate("/");
        }
      }
    },
    { enabled: false },
  );

  /**
   * Request car property, it has to be called only if the car
   * is new and owner is not setted yet
   */
  const { isFetching: fetchingProperty, refetch: fetchProperty } = useQuery(
    "fetchCarProperty",
    async () => {
      // console.log("SET CAR PROPERTY CALLED");
      if (!fetchingProperty) {
        // console.log(
        //   "SetCarProperty PARAMS:",
        //   user.sub || user.uid,
        //   user.address,
        //   currentCar.vin,
        //   currentCar.model,
        //   getRegion(),
        // );

        const status = await setCarProperty(
          isOwnedByOthers && isNftMinted,
          user.sub || user.uid,
          user.address,
          currentCar.vin,
          currentCar.model,
          getRegion(),
        );

        if (status === "logout") {
          signOut();
        } else if (status) {
          //let status = await waitIndidTask(taskId);

          if (status === "SUCCESS") {
            toast.success(i18n.t("messages.owner_setted"), TOAST_CONFIG);

            // console.log("isOwnedByMe", isOwnedByMe);
            // console.log("isOwnedByOthers", isOwnedByOthers);

            setIsOwnedByMe(false);
            if (!isOwnedByOthers) {
              setCurrentCar(prevVal => ({ ...prevVal, tokenId: 0 }));
            }
            setRequestButtonDisabled(false);
            setTimeout(() => {
              // console.log("Timeout on redirect Home");
              setDisableMainButton(false);
              if (isOwnedByOthers) {
                // console.log("REDIRECT TO HOME");
                saveAction(null);
                navigate("/");
              }
            }, 2500);
          } else {
            setDisableMainButton(false);
            setRequestButtonDisabled(false);
            throw new Error();
          }
        } else {
          console.log("[useQuery-setCarProperty] error");
          toast.error(i18n.t("errors.ownership"), TOAST_CONFIG);
          setRequestButtonDisabled(false);
          return;
        }
      }
    },
    { enabled: false, retry: false },
  );

  const triggerCreation = async () => {
    // console.log("triggerCreation CALLED");
    const { abi, certificate: address } = getCarObject(currentCar.model);
    const carContract = sdk.getContract(address, abi);

    // console.log("carContract: ", carContract);

    const carOwner = (
      await carContract.getCarOwner(
        ethers.utils.keccak256(Buffer.from(currentCar.vin)),
      )
    ).toLowerCase();

    // console.log("carOwner: ", carOwner);
    if (carOwner !== user.address) {
      setCarPropertyModalOpen(true);
    } else {
      if (
        currentCar.mileage &&
        currentCar.vin &&
        currentCar.model &&
        currentCar.brand
      ) {
        // console.log("createNFT in triggerCreation CALLED");
        setLoader(true);
        createNFT();
      } else toast.error(i18n.t("errors.create_nft_problem"), TOAST_CONFIG);
    }
  };

  useEffect(() => {
    if (!currentCar) return;

    const checkOwnership = async () => {
      // console.log("checkOwnership Called");
      const { abi, certificate: address } = getCarObject(currentCar.model);
      const carContract = sdk.getContract(address, abi);

      // console.log("carContract: ", carContract);

      if (currentCar.vin) {
        const ownershipAddress = (
          await carContract.getCarOwner(
            ethers.utils.keccak256(Buffer.from(currentCar.vin)),
          )
        ).toLowerCase();

        const tokenId = Number(
          await carContract.getCarTokenId(
            ethers.utils.keccak256(Buffer.from(currentCar.vin)),
          ),
        );

        // console.log("tokenId", tokenId);
        // console.log("ownershipAddress", ownershipAddress);
        // console.log("user.address", user.address);

        const isNotMine = ownershipAddress !== user.address;

        const addressZero = ethers.constants.AddressZero;

        // if (isNotMine && ownershipAddress !== addressZero) {
        //   setDisableMainButton(true);
        // } else {
        setIsOwnedByMe(isNotMine);
        setIsOwnedByOthers(isNotMine && ownershipAddress !== addressZero);
        setIsNftMinted(tokenId && tokenId !== 0);
        if (isNotMine) setCarPropertyModalOpen(true);
        // }
      }
    };

    checkOwnership();
  }, [currentCar]);

  const requestCarProperty = () => {
    return (
      <div
        //password-modal-container (check justification)
        className="box-border flex flex-col items-center w-full h-full pt-2 pb-8 rounded-20 xs:justify-start justify-evenly bg-primaryBackground"
      >
        <div
          //popup=title
          className="p-5 text-lg text-secondaryGrey text-center"
        >
          {isOwnedByOthers && isNftMinted
            ? i18n.t("notarize.request_transfer_text")
            : i18n.t("notarize.request_property_text")}

          <StyledButton
            styleType="primary"
            isDisabled={isRequestButtonDisabled}
            onClick={() => {
              setRequestButtonDisabled(true);
              fetchProperty();
              setCarPropertyModalOpen(false);
            }}
          >
            {isOwnedByOthers && isNftMinted
              ? i18n.t("notarize.request_transfer")
              : i18n.t("notarize.request_property")}
          </StyledButton>
          <StyledButton
            styleType="secondary"
            onClick={() => {
              setDisableMainButton(false);
              setCarPropertyModalOpen(false);
            }}
          >
            {i18n.t("notarize.cancel")}
          </StyledButton>
        </div>
      </div>
    );
  };

  return loader ||
    [
      "EXECUTED",
      "NOT_FOUND",
      "REVERTED",
      "UNHANDLED",
      "FAILED",
      "TIMEOUT",
    ].includes(nft?.status) ? (
    <SplashScreenStepper
      steps={MINT_STEPS}
      currentStep={step}
      // requestId={hashedVin}
      type={loader ? "pending" : !error ? "success" : "failure"}
      message={
        loader
          ? i18n.t("messages.notarization_pending")
          : !error
          ? i18n.t("messages.notarization_success")
          : i18n.t("messages.notarization_failed")
      }
    />
  ) : (
    <div className="w-full h-full bg-primaryBackground pt-topBarHeight">
      <CustomModal
        opened={carPropertyModalOpen}
        content={requestCarProperty()}
        onBackdropClick={() => {
          setCarPropertyModalOpen(false);
          setDisableMainButton(false);
        }}
      />

      <div className="fixed top-0 flex items-center justify-between w-full px-4 py-5 max-w-600 bg-secondaryBackground h-topBarHeight">
        <button
          onClick={() => {
            if (currentCar && carsData.length > 1) {
              setCurrentCar(null);
            } else {
              navigate("/");
            }
            saveAction(null);
          }}
        >
          <Icon
            name={Icons.ARROW}
            fill={colors.white}
            style={{
              width: 20,
              height: 20,
            }}
          />
        </button>
        <span className="text-xl text-white">
          {i18n.t("topBar.vehicle_data")}
        </span>
        <Icon
          name={Icons.ALFA_ROMEO_LOGO_BI}
          fill={colors.white}
          style={{
            width: 25,
            height: 25,
          }}
        />
      </div>

      {fetchingCars || fetchingProperty ? (
        <div className="flex items-center justify-center h-full">
          <Lottie
            className="w-48 h-48 mt-10"
            style={{
              top: "calc(50%-100px)",
              left: "calc(50%-100px)",
              zIndex: "99",
            }}
            play
            animationData={Loading}
          />
        </div>
      ) : !currentCar && carsData.length ? (
        <div className="p-4 bg-primaryBackground">
          <div className="mb-4">
            <span className="text-white text-16">
              {i18n.t("notarize.select_model")}
            </span>
          </div>
          <div className="flex flex-col w-full gap-4">
            {carsData.map((car, i) => (
              <CarSimpleCard
                brand={car.brand}
                model={car.model}
                vin={car.vin}
                onClick={() => setCurrentCar(car)}
                key={i}
              />
            ))}
          </div>
        </div>
      ) : currentCar ? (
        <div className=" bg-primaryBackground">
          <img
            src={getCarObject(currentCar.model)?.img || ""}
            alt={currentCar.model}
            // className="object-cover w-full max-h-250 h-250"
          />

          <div className="flex flex-col items-center justify-between w-full">
            <div className="w-full pb-20">
              {["brand", "model", "vin", "mileage"]?.map(key => (
                <div
                  key={key}
                  className="flex items-center justify-between h-8 p-5 mx-2 my-4 bg-white rounded-md"
                >
                  <span className="w-3/4 text-sm font-bold whitespace-normal text-greyDemo">
                    {i18n.t(`carField.${key}`).toUpperCase()}
                  </span>
                  <div className="flex items-center justify-end whitespace-nowrap">
                    <span className="mr-2 text-16">
                      {currentCar[key]?.toString().toUpperCase()}
                    </span>
                    {UNIT[key] && (
                      <span className="align-text-bottom text-greyDemo text-12">
                        {UNIT[key]}
                      </span>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className="fixed flex items-center justify-center w-full max-w-600 bottom-4">
              <StyledButton
                isDisabled={disableMainButton}
                styleType="primary"
                onClick={() => {
                  setDisableMainButton(true);
                  triggerCreation();
                }}
              >
                {isOwnedByMe
                  ? i18n.t("notarize.request_property")
                  : currentCar.tokenId
                  ? i18n.t("notarize.update_nft")
                  : i18n.t("notarize.create_nft")}
              </StyledButton>
            </div>
          </div>
        </div>
      ) : undefined}
    </div>
  );
}
