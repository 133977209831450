import { useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../redux-observables/firebase/context";

export default function Redirects() {
  const navigate = useNavigate();
  const location = useLocation();

  const { saveAction, getGigyaToken } = useContext(AuthContext);

  useEffect(() => {
    const effect = async () => {
      if (location.search) {
        const mode =
          location.search.match(/\?mode=(.*?)&/)?.[1] ||
          location.search.match(/\?mode=(.*?)$/)?.[1];
        // $/)?.[1];
        const oobCode = location.search.match(/&oobCode=(.*)/)?.[1];
        const gigyaCode = location.search.match(/&code=(.*)/)?.[1];

        // console.log(`MODE - ${mode}`);

        switch (mode) {
          case "createNFT":
            // console.log("navigate to /auth in redirects");
            saveAction(mode);
            navigate("/auth");
            break;

          case "postAuth":
            await getGigyaToken(gigyaCode);
            saveAction("createNFT");
            navigate("/auth");
            break;

          case "user_cancel":
            navigate("/auth");
            break;

          default:
            navigate({ pathname: `/${mode}`, state: { oobCode } });
            break;
        }
      }
    };
    effect();
  }, []);

  return null;
}
