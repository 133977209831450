//react
import { useContext, useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
//i18n
import i18n from "../../imports/i18n";
//formik
import { Formik } from "formik";
//config
import {
  changePasswordInitialValue,
  changePasswordValidation,
} from "../../imports/config";
//components
import {
  CustomInput,
  StyledButton,
  CustomModal,
  CustomLabel,
  Loader,
} from "../../components/index";
// import { useEnv } from "../../imports/hooks";
import { AuthContext } from "../../redux-observables/firebase/context";

function Profile(props) {
  const { loading } = props;

  const { user, uploadProPic } = useContext(AuthContext); //add func name

  const [showModal, handleModal] = useState(false);
  const [modalType, selectModal] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (file) {
      // file.file.forEach(function (value, key) {
      //   file.file[key] = value;
      // });
      console.log(file);
      uploadProPic(file);
    }
  }, [file]);

  // console.log("user: ", user);

  // const handleLanguageIT = () => {
  //   // setLanguage({ it: true, en: false });
  //   changeLanguage("it");
  //   i18n.changeLanguage("it");
  //   closeModal();
  // };

  // const handleLanguageEN = () => {
  //   // setLanguage({ it: false, en: true });
  //   changeLanguage("en");
  //   i18n.changeLanguage("en");
  //   closeModal();
  // };

  // useEffect(() => {
  //   if (file) {
  //     updateUserPhoto({ photo: file });
  //   }
  // }, [file]);

  // const preferences = [
  //   {
  //     label: i18n.t("preferences.language"),
  //     action: "button",
  //     function: () => {
  //       selectModal("language");
  //       openModal();
  //     },
  //   },

  //   {
  //     label: i18n.t("preferences.commercial_email"),
  //     action: "switch",
  //     onFunction: () =>
  //       updateUserInfo({
  //         checks: {
  //           ...user.checks,
  //           commercial: !user.checks.commercial,
  //         },
  //       }),
  //     offFunction: () =>
  //       updateUserInfo({
  //         checks: {
  //           ...user.checks,
  //           commercial: !user.checks.commercial,
  //         },
  //       }),

  //     state: user.checks.commercial,
  //   },
  // ];

  const changePasswordPopupForms = [
    {
      label: i18n.t("profile.prev_password"),
      type: "password",
      placeholder: i18n.t("forms_placeholders.password"),
      formName: "previousPassword",
    },
    {
      label: i18n.t("profile.new_password"),
      type: "password",
      placeholder: i18n.t("forms_placeholders.password"),
      formName: "newPassword",
    },
    {
      label: i18n.t("profile.repeat_new_password"),
      type: "password",
      placeholder: i18n.t("forms_placeholders.password"),
      formName: "repeatPassword",
    },
  ];

  // const openModal = () => {
  //   handleModal(true);
  // };

  const closeModal = () => {
    handleModal(false);
  };

  const modalContent = type => {
    switch (type) {
      case "password":
        return (
          <div
            //password-modal-container (check justification)
            className="box-border flex flex-col items-center w-full h-full pt-2 pb-8 overflow-x-hidden overflow-y-scroll xs:justify-start justify-evenly"
          >
            <div
              //popup=title
              className="pt-5 pb-8 text-xl text-primary"
            >
              {i18n.t("profile.modify_password")}
            </div>

            <Formik
              initialValues={changePasswordInitialValue}
              validationSchema={changePasswordValidation}
              onSubmit={values => {
                // console.log(values);
                closeModal();
              }}
            >
              {({ handleChange, handleSubmit, values, errors }) => (
                <>
                  {changePasswordPopupForms.map((el, i) => (
                    <CustomInput
                      key={i}
                      type={el.type}
                      label={el.label}
                      value={values[el.formName]}
                      placeholder={el.placeholder || null}
                      onChange={handleChange(el.formName)}
                      errors={errors[el.formName]}
                    />
                  ))}
                  <div
                    //change-password-buttons
                    //FIX xs:min-h-[140px]
                    className="w-4/5 h-32 flex flex-col items-center justify-around xs:min-h-[140px]"
                    style={{ marginTop: "40px" }}
                  >
                    <StyledButton styleType="primary" onClick={handleSubmit}>
                      {i18n.t("profile.apply_changes")}
                    </StyledButton>
                    <StyledButton styleType="secondary" onClick={closeModal}>
                      {i18n.t("profile.cancel")}
                    </StyledButton>
                  </div>
                </>
              )}
            </Formik>
          </div>
        );

      // case "language":
      //   return (
      //     <div className="flex flex-col items-center w-full h-full pb-8">
      //       <div className="relative flex items-center justify-between w-full pt-5 pb-5 text-xl font-semibold text-primary">
      //         {/* <div
      //                           style={{ width: "40px", height: "40px" }}
      //                       ></div> */}
      //         {i18n.t("preferences.language_selector")}
      //         <CircleIconButton
      //           onClick={() => {
      //             closeModal();
      //           }}
      //           icon={
      //             <Icon
      //               name={Icons.CROSS}
      //               fill={colors.primary}
      //               style={{
      //                 width: 30,
      //                 height: 30,
      //               }}
      //             />
      //           }
      //           styleType={"secondary"}
      //         />
      //       </div>

      //       <StyledButton
      //         styleType={language === "it" ? "primary" : "secondary"}
      //         onClick={handleLanguageIT}
      //         leftIcon={
      //           language === "it" && (
      //             <Icon
      //               name={Icons.FAVORITESTAR}
      //               style={{
      //                 width: 20,
      //                 height: 20,
      //               }}
      //             />
      //           )
      //         }
      //       >
      //         {i18n.t("preferences.italian")}
      //       </StyledButton>
      //       <StyledButton
      //         styleType={language === "en" ? "primary" : "secondary"}
      //         onClick={handleLanguageEN}
      //         leftIcon={
      //           language === "en" && (
      //             <Icon
      //               name={Icons.FAVORITESTAR}
      //               style={{
      //                 width: 20,
      //                 height: 20,
      //               }}
      //             />
      //           )
      //         }
      //       >
      //         {i18n.t("preferences.english")}
      //       </StyledButton>
      //     </div>
      //   );

      default:
        return <div>no modal selected</div>;
    }
  };

  const userInfo = {
    private: [
      { label: "email", key: "email" },
      { label: "name", key: "name" },
      { label: "surname", key: "surname" },
      { label: "address", key: "address" },
      { label: "privateKey", key: "privateKey" },
      { label: "mnemonic", key: "mnemonic" },

      // { key: "phone" },
      // { key: "pec" },
    ],
    // company: [
    //   { label: "vat", key: "vat" },
    //   { label: "sdi", key: "sdi" },
    //   { label: "username", key: "username" },
    //   { label: "address", key: "wallet.address", tooltip: true },
    //   { label: "privateKey", key: "wallet.privateKey", tooltip: true },
    //   // { key: "phone" },
    //   // { key: "pec" }, //TODO: remove phone and pec everywhere if needed
    // ],
  };
  // const personalInfo = {
  // private: ["username", "email"],
  //company: ["company_name", "email"],
  // };
  // const enabledCopy = { privateKey: true, address: true, mnemonic: true };

  return loading ? (
    <Loader />
  ) : (
    // <div className={`min-h-screen`}>
    <div className="px-30">
      <CustomModal
        opened={showModal}
        onBackdropClick={closeModal}
        content={modalContent(modalType)}
      />

      {/* <div
        //personal-info //FIX media screen
        className="flex"
      >
        <div
          //personal-info-data
          className="flex flex-col w-3/5"
        >
          {personalInfo["private"].map((key, i) => {
            const value = user[key];
            return (
              <CustomLabel
                key={i}
                styleType={"tertiary"}
                label={i18n.t(`profile.${key}`)}
                textComponent={value}
                bottomRightItem={key.bottomRightItem}
                textCut={key.textCut}
              />
            );
          })}
        </div>
        <div
          //photo-container
          className="flex-1 my-auto ml-8"
        >
          <>

            <ProPicInput
              className="box-border relative px-3 py-4 text-black bg-center bg-no-repeat shadow-none w-44 h-44 bg-upload"
              styleType="avatarUpload"
              onChange={imageObj => {
                if (imageObj) {
                  //getBase64(imageObj, setFile);
                  // let object = {};
                  // imageObj.forEach(function (value, key) {
                  //   object[key] = value;
                  // });
                  // getBase64(object.file).then(res => {
                  //   setFile({
                  //     file: imageObj,
                  //     name: object.file.name,
                  //     blob: res,
                  //   });
                  // });
                  setFile(imageObj);
                  getBase64(imageObj).then(res => setProPic(res));
                }
              }}
            >
              {file || user.propic ? (
                // <AvatarImage imgBlob={file.blob || user.profilePhoto} />
                <img
                  src={proPic || user.propic}
                  alt={i18n.t("loading")}
                  className={`w-full h-full bg-primaryBackground`}
                />
              ) : (
                ""
              )}
            </ProPicInput>
          </>
        </div>
      </div> */}
      <div>
        {userInfo["private"].map(({ key, tooltip, label }) => {
          // console.log({ key, tooltip, label });
          return (
            <CustomLabel
              key={key}
              styleType={"tertiary"}
              label={i18n.t(`profile.${label}`)}
              textComponent={user[key]}
              copy={
                key === "address" || key === "privateKey" || key === "mnemonic"
              }
              tooltipText={tooltip ? i18n.t(`profile.${key}_tooltip`) : ""}
            />
          );
        })}
      </div>
      {/* <div
        //account-preferences-container
        className="box-border w-full"
      >
        {preferences.map((el, i) => (
          <div
            key={i}
            //CHECK
            //className={`row-container ${
            //    el.disabled ? "disabled" : ""
            //}`}
            className={`w-full flex justify-between items-center flex-row text-xl pt-4 pb-4 box-border font-regular ${
              el.disabled ? "text-secondary" : "text-primary"
            }`}
          >
            {el.label}
            {el.action === "button" ? (
              <CircleIconButton
                onClick={el.function}
                icon={
                  <Icon
                    name={Icons.PENCIL}
                    fill={colors.secondary}
                    style={{
                      width: 15,
                      height: 15,
                    }}
                  />
                }
                styleType="primary"
                size="small"
                style={{ marginRight: 7 }}
                disabled={el.disabled}
              />
            ) : (
              <CustomSwitch
                relatedSwitch={el.relatedSwitch ? activeSwitch : true}
                startingValue={el.state}
                onFunction={el.onFunction}
                offFunction={el.offFunction}
                disabled={el.disabled || false}
              />
            )}
          </div>
        ))}
        <div
          //contact-support-message-one
          className={`w-full text-center text-xl pt-5 box-border font-normal pl-halfMargin pr-halfMargin mt-8 text-black`}
          style={{ wordBreak: "break-word" }}
        >
          {i18n.t("preferences.message_support_one")}
        </div>
        <div
          onClick={() =>
            window.open(
              `mailto:${i18n.t("preferences.message_support_two")}`,
              "_blank",
            )
          }
          //contact-support-message-two
          className={`w-full text-center text-xl pt-5 box-border font-normal pl-halfMargin pr-halfMargin text-primary`}
          style={{
            paddingBottom: "40px",
            cursor: "pointer",
            wordBreak: "break-word",
          }}
        >
          {i18n.t("preferences.message_support_two")}
        </div>
      </div> */}
    </div>
  );
}

export default Profile;
