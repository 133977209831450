//react
import { useState, useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";

//components
import {
  TopBar,
  DrawerMenu /* DrawerMenuDesktop */,
  SplashScreen,
  CustomModal,
  StyledButton,
  CustomInput,
} from "./components/index";
import { Loading } from "./imports/animations";

//config
import { routes } from "./imports/config";
import { recoveryPasswordValidation } from "./pages/home/FormType";
import { sdk } from "./imports/utils";
import { ToastContainer } from "react-toastify";

import i18n from "./imports/i18n";

import packageJson from "../package.json";
import { AuthContext } from "./redux-observables/firebase/context";
import { colors } from "./imports/constants";
import Lottie from "react-lottie-player";
import { Formik } from "formik";
import { getMainEntryPointUrl } from "./imports/utils";

import { Buffer } from "buffer";

window.Buffer = Buffer;

const CustomRoute = ({
  component: Component,
  authed,
  redirect,
  componentProps,
  route,
  logout,
  location,
  errors,
  onAlertClose,
  isLoading,
  ...getInitialProps
}) => {
  const navigate = useNavigate();

  const { action, user, saveRegion, recoverWallet, loading } =
    useContext(AuthContext);

  const [isOpen, setIsOpen] = useState(false);
  function toggleDrawer(event) {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsOpen(!isOpen);
  }

  const recoverModal = () => {
    return (
      <div
        id="recoverModal"
        className={`flex flex-col justify-start items-center p-10 bg-primaryBackground`}
      >
        <div
          //title
          className="flex justify-center w-full pb-8 text-4xl text-secondaryGrey"
        >
          {i18n.t("recover_wallet.recover")}
        </div>

        {loading ? (
          <div id="loadingSpinner" style={{ backgroundColor: colors.white }}>
            <Lottie
              loop={true}
              play
              animationData={Loading}
              className={"loading-spinner"}
              style={{ position: "relative" }}
            />
          </div>
        ) : (
          <>
            <div
              //text
              className="flex justify-center w-full pb-8 text-xl text-secondaryGrey"
            >
              {i18n.t("recover_wallet.recovery_text")}
            </div>
            <Formik
              initialValues={{
                newPassword: "",
                seed: "",
              }}
              validationSchema={recoveryPasswordValidation}
              onSubmit={values => {
                recoverWallet(values);
              }}
            >
              {({
                handleChange,
                handleSubmit,
                values,
                errors,
                setFieldValue,
              }) => (
                <div
                  //input-container
                  className="flex flex-col items-center justify-center w-full h-full"
                >
                  <CustomInput
                    type={"password"}
                    label={i18n.t("recover_wallet.new_password")}
                    value={values.newPassword}
                    onChange={e => setFieldValue("newPassword", e)}
                    errors={errors.newPassword}
                  />
                  <CustomInput
                    type={"password"}
                    label={i18n.t("recover_wallet.seed")}
                    value={values.seed}
                    onChange={e => setFieldValue("seed", e)}
                    errors={errors.seed}
                  />
                  <StyledButton onClick={handleSubmit}>
                    {i18n.t("recover_wallet.recover")}
                  </StyledButton>
                </div>
              )}
            </Formik>
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const isEntered = localStorage.getItem("userEntered");

    if (getMainEntryPointUrl().includes(url.href) && !isEntered) {
      const searchParams = url.searchParams;
      let region = searchParams.get("region");

      if (!region) {
        region = "eu";
        console.log("[init] region is not preset. setting the default", region);
      } else {
        console.log("[init] region from url", region);
      }

      saveRegion(region, true);
    }

    if (!isLoading && !authed) {
      // console.log("[init] redirect to -> ", redirect);
      navigate(redirect);
    }

    if (user.logged && user.new) {
      // console.log("[init] redirect to -> /mnemonicDisplay");
      navigate("/mnemonicDisplay");
    } else if (user.logged && action) {
      // console.log("[init] redirect to -> /${action} ", action);
      navigate(`/${action}`);
    }
  }, [isLoading, authed, redirect, user, action]);

  if (isLoading) {
    // Mostra SplashScreen se la pagina sta caricando
    return (
      <div className="flex flex-row items-center justify-center h-screen overflow-hidden bg-fakeDesktopBlack">
        <div
          className={`w-full max-w-600 h-full relative box-border bg-white overflow-auto`}
        >
          <SplashScreen type={"pending"} />
        </div>
      </div>
    );
  }

  if (!authed) {
    // Reindirizza se non autenticato
    //navigate(redirect);
    return null;
  }

  return (
    <div className="flex flex-row items-center justify-center h-screen overflow-hidden bg-fakeDesktopBlack">
      {route.hasTopBar && (
        <TopBar
          admin={user.role === "admin"}
          onClickLeft={
            route.customBack ? () => navigate("/auth") : toggleDrawer
          }
          onClickRight={() => navigate(route.topBarFunction)}
          rightIcon={route.topBarIcon}
          goBack={route.goBack}
          title={i18n.t(
            componentProps.location?.state?.topBarLabel
              ? componentProps.location.state.topBarLabel
              : route.topBarLabel,
          )}
          logo={route.hasLogo}
          customBack={route.customBack}
        />
      )}

      <>
        <div
          className={`w-full max-w-600 h-full relative box-border bg-primaryBackground overflow-auto ${
            route.hasTopBar
              ? "overflow-y-auto pt-topBarHeight"
              : route.onboarding
              ? "w-full relative box-border bg-primaryBackground"
              : ""
          } `}
        >
          {route.hasDrawer && (
            <DrawerMenu
              isOpen={isOpen}
              toggleDrawer={toggleDrawer}
              logout={logout}
              user={user}
            />
          )}
          <Component {...componentProps} />
        </div>
      </>

      <CustomModal
        opened={user.logged && user.needRecover}
        content={recoverModal()}
        className={"bg-primaryBackground"}
      />

      <ToastContainer />
    </div>
  );
};

export default function App(props) {
  const { user, signOut, isLoading } = useContext(AuthContext);

  useEffect(() => {
    window.scrollTo(0, 0);

    const effect = async () => {
      if (localStorage.getItem("version") !== packageJson.version) {
        localStorage.setItem("version", packageJson.version);
        signOut();
        // toast.warning(i18n.t("alert.big_changes"), TOAST_CONFIG);
      }

      if (!user.needRecover) {
        if (user.logged) {
          if (!sdk.isInitialized()) {
            await sdk.init();
          }

          if (!sdk.getWalletAddress()) {
            sdk.setPrivateKey(user?.wallet?.privateKey);
          }
        }
      }
    };

    effect();
  }, [user]);

  return (
    <Router>
      <Routes>
        {routes.map((route, i) => {
          let componentProps = {};
          route.props?.forEach(prop => {
            componentProps[prop] = props[prop];
          });

          return (
            <Route
              key={i}
              path={route.path}
              element={
                <CustomRoute
                  authed={
                    (!!user?.logged && route?.logged) ||
                    (!user?.logged && !route?.logged)
                  }
                  redirect={route.redirect}
                  component={route.component}
                  componentProps={componentProps}
                  route={route}
                  logout={signOut}
                  user={user}
                  isLoading={isLoading}
                />
              }
            />
          );
        })}
      </Routes>
    </Router>
  );
}
